/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/layout';
import { getTranslate } from 'react-localize-redux';
import {
  hideAllNotifications,
  showNotification,
  SEVERITY,
} from '../state/notifications';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Form } from 'formik';
import { Container } from '../components';
import Textarea from 'react-textarea-autosize';
import { withFormik } from 'formik';
import RecaptchaButton from '../components/RecaptchaButton';
import * as api from '../utils/api';
import { handleFormValidationError } from '../utils/formError';
import { locNavigate } from '../state/session';
import Spinner from '../components/Spinner';
import * as queryString from 'query-string';
import * as analytics from '../utils/analytics';

const salesContactFormSchema = translate =>
  yup.object().shape({
    email: yup
      .string()
      .required(translate('salesContactForm.messages.requiredField'))
      .email(translate('salesContactForm.messages.invalidEmail')),
    message: yup
      .string()
      .required(translate('salesContactForm.messages.requiredField')),
    name: yup
      .string()
      .required(translate('salesContactForm.messages.requiredField')),
    recaptcha: yup.string().required(),
  });

const SalesContactFormForm = ({
  translate,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  nocaptcha,
}) => {
  return (
    <Form>
      <FormField name="email" label={translate('salesContactForm.email')} />
      <FormField
        name="message"
        as={Textarea}
        label={translate('salesContactForm.message')}
      />
      <FormField name="name" label={translate('salesContactForm.name')} />
      <RecaptchaButton
        buttonText="salesContactForm.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
      />
    </Form>
  );
};

const SalesContactFormFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      name: vo.name || '',
      message: vo.message || '',
      email: vo.email || '',
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return salesContactFormSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'SalesContactInputForm',
})(SalesContactFormForm);

const SalesContactFormPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());
      try {
        await api.sendSalesContactForm({ ...vo, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: { message: translate('salesContactForm.messages.success') },
          })
        );
        //dispatch(showNotification('salesContactForm.messages.success', SEVERITY.INFO));
        setShowSpinner(false);
        setSubmitting(false);
      } catch (error) {
        setShowSpinner(false);
        setSubmitting(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(
          showNotification('salesContactForm.messages.failed', SEVERITY.ERROR)
        );
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout
      title={translate('salesContactForm.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('salesContactForm.title')}</Styled.h1>
        <SalesContactFormFormik key={locale} {...formProps} />
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default SalesContactFormPage;
